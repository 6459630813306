export default {
  userName(userName) {
    const regex = /^[가-힣a-zA-Z]{1,10}$/;

    return regex.test(userName);
  },
  email(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return regex.test(email);
  },
  password(password) {
    const regex =
      /^(?!.*[^a-zA-Z0-9!@#$%*,.?{}[\]|])(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*,.?{}[\]|]).{10,16}$/;

    return regex.test(password);
  },
  passwordWhiteList(password) {
    const regex = /^[a-zA-Z0-9!@#$%*,.?{}[\]|]+$/;

    return regex.test(password);
  },
  phoneNumber(num) {
    const regex = /^(01[016789]{1})\s?-?\s?[0-9]{3,4}\s?-?\s?[0-9]{4}$/;

    return regex.test(num);
  },
  userAvatar(userAvatar) {
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!allowedTypes.includes(userAvatar?.type)) {
      return false;
    }
    return true;
  },
  projectName(projectName) {
    const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]{1,10}$/;

    return regex.test(projectName);
  },
  designName(projectName) {
    const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]{1,10}$/;

    return regex.test(projectName);
  },
  hexCode(hex) {
    const regex = /^#(?:[0-9a-f]{3}){1,2}$/i;

    return regex.test(hex);
  },
};
